import { SystemStyleObject } from "@styled-system/css";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import React from "react";
import { get } from "../constants/theme";
import { SectionElement } from "../types/common/section";
import Appear from "./Animations/AppearContainer";
import Box from "./Box/Box";
import { Paragraph, Title } from "./Styled/Text";

type Props = {
  sectionElm: SectionElement;
  isPageOnLeft: boolean;
  sx?: SystemStyleObject;
  sxDates?: SystemStyleObject;
  sxTextContainer?: SystemStyleObject;
  sxImageContainer?: SystemStyleObject;
  isAnimated?: boolean;
  children?: React.ReactNode;
};

type TextContainerProps = {
  sxTextContainer?: SystemStyleObject;
  sxDates?: SystemStyleObject;
  sectionElm: Omit<SectionElement, "image" | "imageAlt">;
  children?: React.ReactNode;
};

const SectionTextBlock = React.memo(
  ({ sectionElm, sxTextContainer, children, sxDates }: TextContainerProps) => {
    return (
      <Box
        sx={{
          alignSelf: ["flex-start", "flex-start", "center", "center"],
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: ["flex-start", "flex-start", "center", "center"],
          marginBottom: ["75px", "75px", 0, 0],
          ...sxTextContainer,
        }}
      >
        <Box sx={{ position: "relative", width: "100%" }}>
          {sectionElm.dates && (
            <Box
              sx={{
                position: "absolute",
                fontSize: "32px",
                height: "32px",
                fontFamily: "custom_42269",
                color: get("palette.text.gold"),
                top: "-61px",
                fontFeatureSettings:
                  '"liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0',
                textRendering: "optimizeLegibility",
                fontSmooth: "antialiased",
                ...sxDates,
              }}
              as="span"
            >
              {sectionElm.dates}
            </Box>
          )}
        </Box>

        <Title sx={{ width: "100%" }} variant="paragraph">
          {sectionElm.title}
        </Title>
        <Paragraph>{sectionElm.description}</Paragraph>
        {children}
      </Box>
    );
  }
);

const SectionTextBlockAnimated = React.memo(
  ({ sectionElm, sxTextContainer, children, sxDates }: TextContainerProps) => {
    return (
      <Box
        sx={{
          alignSelf: ["flex-start", "flex-start", "center", "center"],
          width: "100%",

          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: ["flex-start", "flex-start", "center", "center"],
          marginBottom: ["75px", "75px", 0, 0],
          ...sxTextContainer,
        }}
      >
        {sectionElm.dates && (
          <Appear
            wrapperCmp={Box}
            sx={{
              position: "absolute",
              fontSize: "32px",
              height: "32px",
              fontFamily: "custom_42269",
              color: get("palette.text.gold"),
              top: "-61px",
              ...sxDates,
            }}
            as="span"
          >
            {sectionElm.dates}
          </Appear>
        )}

        <Appear wrapperCmp={Title} sx={{ width: "100%" }} variant="paragraph">
          {sectionElm.title}
        </Appear>
        <Appear wrapperCmp={Paragraph}>{sectionElm.description}</Appear>
        {children}
      </Box>
    );
  }
);

const HistorySection = ({
  sectionElm,
  isPageOnLeft,
  sx,
  sxDates,
  sxTextContainer,
  sxImageContainer,
  children,
  isAnimated = false,
}: Props) => {
  const TextCmp = isAnimated ? SectionTextBlockAnimated : SectionTextBlock;
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: [
          "column-reverse",
          "column-reverse",
          isPageOnLeft ? "row" : "row-reverse",
          isPageOnLeft ? "row" : "row-reverse",
        ],
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginBottom: ["10rem", "10rem", "10rem", "10rem"],
        "& > :nth-child(1n)": {
          padding: "0 5%",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box
            as={Image}
            sx={{
              width: "100%",
              maxWidth: ["293px", "476px", "359px", "448px"],
              height: ["419px", "677px", "511px", "633px"],
              ":nth-child(1n)": {
                margin: "0 auto",
                marginBottom: "20px",
              },
              ...sxImageContainer,
            }}
            image={sectionElm.image.childImageSharp.gatsbyImageData}
            alt={sectionElm.imageAlt}
            // placeholder="blurred"
            // layout="fixed"
            // width={200}
            // height={200}
          />
          <Box
            as="span"
            sx={{
              maxWidth: ["293px", "476px", "359px", "448px"],
              display: ["none", "none", "block", "block"],
              fontFamily: "custom_42266",
              fontWeight: 400,
              fontSize: "12px",
              color: get("palette.text.dark_gold"),
              margin: "0 auto",
            }}
          >
            {sectionElm.imageAlt}
          </Box>
        </Box>
      </Box>
      <TextCmp
        sectionElm={sectionElm}
        sxDates={sxDates}
        sxTextContainer={sxTextContainer}
      >
        {children}
      </TextCmp>
    </Box>
  );
};

export default React.memo(HistorySection);
