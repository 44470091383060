import { useTransform, useViewportScroll } from "framer-motion";
import { InputRange } from "framer-motion/types/value/use-transform";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import MotionBox from "./Box/MotionBox";

interface Props {
  inputRange: InputRange;
  outputRange: number[];
  colorsIputRange: InputRange;
  colorsOutputRange: string[];
}

const ScrollAvailable = ({
  inputRange,
  outputRange,
  colorsIputRange,
  colorsOutputRange,
}: Props) => {
  const { text } = useStaticQuery(graphql`
    query ScrollQuery {
      translationsJson {
        components {
          scroll {
            text
          }
        }
      }
    }
  `).translationsJson.components.scroll;
  const { scrollY } = useViewportScroll();
  const opacity = useTransform(scrollY, inputRange, outputRange);

  const color = useTransform(scrollY, colorsIputRange, colorsOutputRange);
  return (
    <MotionBox
      sx={{
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        bottom: "5%",
        left: "50%",
        transform: "translateX(-50%)",
        userSelect: "none",
        zIndex: 100,
      }}
      style={{
        // @ts-expect-error
        opacity: opacity,
      }}
    >
      <MotionBox
        sx={{
          position: "relative",
          width: "21px",
          height: "32px",
          borderRadius: "16px",
          borderWidth: "1.5px",
          borderStyle: "solid",
          marginBottom: "8px",
        }}
        style={{ borderColor: color as any }}
      >
        <MotionBox
          sx={{
            position: "absolute",
            width: "4px",
            height: "4px",
            top: "5.67px",
            left: "50%",
            transform: "translateX(-50%) rotate(45deg)",
          }}
          style={{ background: color as any }}
        />
      </MotionBox>
      <MotionBox
        sx={{
          textAlign: "center",
          fontWeight: 400,
          fontSize: "12px",
          fontFamily: "custom_42266",
        }}
        style={{ color: color as any }}
      >
        {text}
      </MotionBox>
    </MotionBox>
  );
};

export default React.memo(ScrollAvailable);
