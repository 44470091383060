import { SystemStyleObject } from "@styled-system/css";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import useWindowSize from "../../hooks/useWindowSize";
import MotionBox from "../Box/MotionBox";

type Props = {
  wrapperCmp?: React.FC;
  children: React.ReactNode;
  triggerOffset?: number;
  sx?: SystemStyleObject;
  [x: string]: any;
};

const Appear = ({
  wrapperCmp = undefined,
  children,
  triggerOffset = 50,
  ...rest
}: Props) => {
  const [offset, setOffset] = React.useState<number>(0);
  const { height } = useWindowSize();
  const { scrollY } = useViewportScroll();

  const opacity = useTransform(
    scrollY,
    [offset - height * 0.75, offset - height * 0.5 + triggerOffset],
    [0, 1]
  );

  const verticalPosition = useTransform(
    scrollY,
    [offset - height, offset - height * 0.5 + triggerOffset],
    [height * 0.1, 0]
  );

  const wrapperRef = React.useCallback((node: HTMLElement | null) => {
    if (node !== null && !isMobileOnly) {
      const parent = node.offsetParent as HTMLElement;
      if (parent) {
        setOffset(node.offsetTop + parent.offsetTop);
      } else {
        setOffset(node.offsetTop);
      }
    } else {
      setOffset(0);
    }
  }, []);

  const Wrapper = wrapperCmp ? motion(wrapperCmp) : MotionBox;

  return (
    <Wrapper
      ref={wrapperRef}
      style={{
        opacity: opacity as any,
        y: verticalPosition as any,
      }}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default React.memo(Appear);
